/** @jsx jsx */
import { jsx } from "theme-ui"

const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "black",
    }}
  >
    <div className="container">
      <p>
        {/* A GatsbyJS Starter for Netlify CMS, Made with{" "}
        <span className="icon -love">
          <RiHeart2Line />
        </span>{" "}
        by <Link to="/">Stackrole.com</Link> */}
        Made by <a href="https://www.rcrlabs.com" >RCR Labs</a>
      </p>
    </div>
  </footer>
)

export default Footer
const navStyle = {
  theme: {
    display: ["block", "block", "block", "flex"],
    // backgroundColor: "white",
    p: " 25px 20px 20px",
  },

}
