/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { jsx } from "theme-ui";

const Logo = props => (
  <div className="site-logo">
    <Link to="/"><span sx={{ color: "textColor", fontSize: 36, padding: "5px" }}>{props.title}</span></Link>
  </div>
)

export default Logo
